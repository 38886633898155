var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-viewer",attrs:{"data-test-id":"LogsOverview"}},[(!_vm.initialLoading)?_c('Toolbar',{attrs:{"data-test-id":"LogsOverviewHeader","disabled":_vm.runningAction,"search-props":{
      possibleFilters: _vm.possibleFilters,
      advancedSearch: true,
    }},on:{"expanded":function($event){_vm.toolbarExpanded = $event}},scopedSlots:_vm._u([{key:"extended",fn:function(){return [_c('div',{staticClass:"d-flex pr-4 pl-1 pt-1 pb-3"},[_c('LogHeaderSelect',{attrs:{"all-headers":_vm.possibleHeaders,"disabled":_vm.runningAction,"data-test-id":"LogHeaderSelect"},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}})],1)]},proxy:true}],null,false,1894598838),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}}):_vm._e(),_c('v-data-table',{staticClass:"mt-3 mx-2",attrs:{"hide-default-footer":"","fixed-header":"","show-expand":"","multi-sort":"","item-key":"_id","height":_vm.tableHeight,"expanded":_vm.expanded,"items-per-page":_vm.limit,"items":_vm.logs,"headers":_vm.headers,"loading":_vm.runningAction || _vm.initialLoading,"options":_vm.options,"server-items-length":_vm.limit,"no-data-text":_vm.noLogsText,"no-results-text":_vm.noLogsText},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var isExpanded = ref.isExpanded;
    var expand = ref.expand;
    var index = ref.index;
return [_c('tr',{class:{
          'v-data-table__expanded v-data-table__expanded__row': isExpanded,
        },attrs:{"data-test-id":'log_entry_' + index}},[_c('td',[(isExpanded)?_c('v-btn',{attrs:{"icon":"","data-test-id":'log_entry_' + index + '_hide_btn'},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","data-test-id":'log_entry_' + index + '_expand_btn'},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_vm._l((_vm.headers),function(header,headerIndex){return _c('td',{key:headerIndex,staticClass:"text-start"},[_c('div',{staticClass:"log-value",attrs:{"data-test-id":'log_entry_' + index + '_column_' + header.value}},[_vm._v(" "+_vm._s(_vm.renderItemProperty(item, header))+" ")])])})],2)]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('tr',{staticClass:"v-data-table__expanded v-data-table__expanded__content",attrs:{"data-test-id":'log_entry_' + index + '_expanded'}},[_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('DataReader',{staticClass:"pa-3",attrs:{"schema":{
              type: 'object',
              additionalProperties: true,
            },"data":item._source,"title":"_source"}})],1)])]}},{key:"body.append",fn:function(ref){
            var items = ref.items;
return [(!_vm.runningAction && items.length === _vm.limit)?_c('tr',[_c('td',{staticClass:"grey lighten-2",attrs:{"colspan":_vm.headers.length + 1}},[_c('div',{staticClass:"d-flex justify-start font-weight-bold"},[_vm._v(" These are the first "+_vm._s(_vm.limit)+" documents matching your search, refine your search to see others ")])])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
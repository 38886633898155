<template>
  <v-autocomplete
    v-model="headers"
    dense
    multiple
    outlined
    small-chips
    hide-details="auto"
    class="ml-3"
    return-object
    prepend-icon="mdi-table"
    label="Headers"
    :items="allHeaders"
    :disabled="disabled"
    :item-disabled="isOnlyHeader"
    data-test-id="logHeaderSelect"
  >
    <template #selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        class="my-1"
        :close="headers.length > 1"
        :disabled="disabled"
        :input-value="selected"
        :data-test-id="'selected_header_' + item.value"
        @click="select"
        @click:close="removeHeader(item)"
      >
        <span>{{ item.text }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },

    allHeaders: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      headers: this.value,
    };
  },

  watch: {
    value(value) {
      this.headers = value;
    },

    headers(headers) {
      this.$emit("input", headers);
    },
  },

  methods: {
    removeHeader(header) {
      const idx = this.headers.findIndex(({ value }) => value === header.value);
      this.headers.splice(idx, 1);
    },

    isOnlyHeader(item) {
      //Disables the item if its the only displayed header,
      //so that there is always one header left
      return (
        this.headers.length === 1 &&
        this.headers.some((header) => header.value === item.value)
      );
    },
  },
};
</script>
<template>
  <div class="log-viewer" data-test-id="LogsOverview">
    <Toolbar
      v-if="!initialLoading"
      v-model="filterQuery"
      data-test-id="LogsOverviewHeader"
      :disabled="runningAction"
      :search-props="{
        possibleFilters,
        advancedSearch: true,
      }"
      @expanded="toolbarExpanded = $event"
    >
      <template #extended>
        <div class="d-flex pr-4 pl-1 pt-1 pb-3">
          <LogHeaderSelect
            v-model="headers"
            :all-headers="possibleHeaders"
            :disabled="runningAction"
            data-test-id="LogHeaderSelect"
          />
        </div>
      </template>
    </Toolbar>
    <v-data-table
      class="mt-3 mx-2"
      hide-default-footer
      fixed-header
      show-expand
      multi-sort
      item-key="_id"
      :height="tableHeight"
      :expanded="expanded"
      :items-per-page="limit"
      :items="logs"
      :headers="headers"
      :loading="runningAction || initialLoading"
      :options.sync="options"
      :server-items-length="limit"
      :no-data-text="noLogsText"
      :no-results-text="noLogsText"
    >
      <template #item="{ item, isExpanded, expand, index }">
        <tr
          :class="{
            'v-data-table__expanded v-data-table__expanded__row': isExpanded,
          }"
          :data-test-id="'log_entry_' + index"
        >
          <td>
            <v-btn
              icon
              v-if="isExpanded"
              @click="expand(false)"
              :data-test-id="'log_entry_' + index + '_hide_btn'"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn
              icon
              v-else
              @click="expand(true)"
              :data-test-id="'log_entry_' + index + '_expand_btn'"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </td>
          <td
            v-for="(header, headerIndex) in headers"
            :key="headerIndex"
            class="text-start"
          >
            <div
              class="log-value"
              :data-test-id="'log_entry_' + index + '_column_' + header.value"
            >
              {{ renderItemProperty(item, header) }}
            </div>
          </td>
        </tr>
      </template>

      <template #expanded-item="{ item, index }">
        <tr
          class="v-data-table__expanded v-data-table__expanded__content"
          :data-test-id="'log_entry_' + index + '_expanded'"
        >
          <td :colspan="headers.length + 1">
            <DataReader
              :schema="{
                type: 'object',
                additionalProperties: true,
              }"
              :data="item._source"
              title="_source"
              class="pa-3"
            />
          </td>
        </tr>
      </template>

      <!-- eslint-disable-next-line -->
      <template #body.append="{ items }">
        <tr v-if="!runningAction && items.length === limit">
          <td :colspan="headers.length + 1" class="grey lighten-2">
            <div class="d-flex justify-start font-weight-bold">
              These are the first {{ limit }} documents matching your search,
              refine your search to see others
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Toolbar from "../common/templates/Toolbar";
import mixin from "../../mixins/elastic-search-mixin";
import DataReader from "../configuration/data/Reader";
import LogHeaderSelect from "./LogHeaderSelect";

const noLogsText = "No log entries for the given parameters";

export default {
  mixins: [mixin],
  inject: {
    getPageHeight: {
      default: () => {
        console.warn("Parent component does not provide method getPageHeight");
      },
    },
  },
  components: {
    Toolbar,
    DataReader,
    LogHeaderSelect,
  },
  data() {
    return {
      possibleFilters: [],
      possibleHeaders: [],
      headers: [],
      defaultHeaders: ["_source.timestamp", "_source.component", "_source"],
      filterQuery: null,
      logs: [],
      runningAction: false,
      loadLogsController: null,
      limit: 200,
      expanded: [],
      toolbarExpanded: true,
      options: {},
      sortableFields: [],
      noLogsText,
      initialLoading: false,
    };
  },

  async created() {
    this.noLogsText = noLogsText;
    const filter = this.$route.query?.filter;
    if (filter) this.filterQuery = JSON.parse(this.$urlDecode(filter));
    this.initialLoading = true;
    try {
      await this.init(true);
    } finally {
      this.initialLoading = false;
    }
  },

  watch: {
    "$route.query": {
      handler: function (newQuery, oldQuery) {
        const filterChanged = !this.$isEqual(newQuery.filter, oldQuery.filter);
        const sortChanged = !this.$isEqual(newQuery.sort, oldQuery.sort);
        if ((filterChanged || sortChanged) && this.$route.name === "logs") {
          //the query consists of filters and sorting so reload
          //the logs if one of those changes
          this.init();
        }
      },
      deep: true,
    },

    filterQuery: {
      handler: function (filterQuery) {
        let query = Object.assign({}, this.$route.query);
        const oldFilterQuery = query.filter;
        const hasFilters =
          filterQuery?.bool?.filter?.length > 0 ||
          filterQuery?.bool?.must?.query_string?.query;
        if (hasFilters) {
          const newFilterQuery = JSON.stringify(filterQuery);
          query.filter = this.$urlEncode(newFilterQuery);
        } else {
          delete query.filter;
        }

        //do not reload if the filter did not change
        if (oldFilterQuery === query.filter) return;

        let route = Object.assign({}, this.$route);
        this.$delete(route.meta, "domainChange");
        this.$set(route, "query", query);
        this.$router.replace(route);
      },
      deep: true,
    },

    options(options) {
      let query = Object.assign({}, this.$route.query);
      let sort = "";
      const sortBy = options.sortBy;
      const sortDesc = options.sortDesc;

      //build sort url parameter value
      for (let i = 0; i < sortBy.length; i++) {
        const sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
        if (!sort) sort = sortQuery;
        else sort += "," + sortQuery;
      }

      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.push({ query });
    },

    headers(headers) {
      let query = Object.assign({}, this.$route.query);
      if (headers.length > 0) {
        const headerString = headers.map((header) => header.value).join(",");
        this.$set(query, "headers", headerString);
      } else {
        this.$delete(query, "headers");
      }

      this.$router.push({ query });

      //Iterate through sortBy and check headers
      let sortBy = this.$cloneObject(this.options.sortBy);
      let sortDesc = this.$cloneObject(this.options.sortDesc);
      if (headers.length > 0 && sortBy && sortDesc) {
        for (let i = sortBy.length - 1; i >= 0; i--) {
          const value = sortBy[i];
          if (!headers.some((header) => header.value === value)) {
            sortBy.splice(i, 1);
            sortDesc.splice(i, 1);
          }
        }

        const changedOptions = {
          ...this.options,
          sortBy,
          sortDesc,
        };
        if (!this.$isEqual(this.options, changedOptions)) {
          this.options = changedOptions;
        }
      }
    },
  },

  methods: {
    async init(initial) {
      //initialize the component with the route parameters
      const namedRoute = this.$route.name;
      if (namedRoute === "logs") {
        const routeQuery = this.$route.query;
        let filter = routeQuery.filter;
        let sort = routeQuery.sort;
        let headers = routeQuery.headers;

        if (initial) await this.loadCapabilities();

        if (filter) {
          try {
            filter = this.$urlDecode(filter);
            this.filterQuery = JSON.parse(filter);
          } catch (e) {
            this.filterQuery = null;
            console.warn(e);
          }
        }

        if (sort) {
          const sortBy = [];
          const sortDesc = [];
          const sortQuery = sort.split(",");

          const addToSorted = (sortString) => {
            //this function parses the given string and adds the
            //sorting information to the respective arrays
            const isDesc = sortString.startsWith("-");
            const property = isDesc ? sortString.substring(1) : sortString;
            const key = property.replace("_source.", "");
            const isSortableField = !!this.sortableFields?.[key];
            if (isSortableField) {
              sortBy.push(property);
              sortDesc.push(isDesc);
            }
          };

          if (Array.isArray(sortQuery))
            sortQuery.forEach((part) => addToSorted(part));
          else addToSorted(sortQuery);
          this.options = Object.assign(this.options, { sortBy, sortDesc });
        }

        if (headers || initial) {
          const headerIds =
            headers?.length > 0 ? headers.split(",") : this.defaultHeaders;
          let headersArray = [];
          headerIds.forEach((id) => {
            const header = this.possibleHeaders.find(
              (header) => header.value === id
            );
            if (header) headersArray.push(header);
          });
          this.headers = headersArray;
        }
        let query = {};
        const hasSort =
          this.options.sortBy?.length > 0 && this.options.sortDesc?.length > 0;

        const hasFilters =
          this.filterQuery?.bool?.filter?.length > 0 ||
          this.filterQuery?.bool?.must?.query_string?.query;

        if (hasFilters) {
          const filterQuery = JSON.stringify(this.filterQuery);
          query.filter = this.$urlEncode(filterQuery);
        }
        if (hasSort) query.sort = sort;

        if (this.headers?.length > 0) {
          query.headers = this.headers.map((header) => header.value).join(",");
        }

        this.$router.replace({
          name: "logs",
          query,
        });
        //update the URL query
        this.$nextTick(() => this.loadLogs());
      }
    },

    async loadCapabilities() {
      const capabilities =
        await this.$store.$coreApi.coreLoggingApi.getCapabilities(
          this.selectedDomain
        );

      this.possibleFilters =
        capabilities?.filters.sort(function (f1, f2) {
          return f1.property.localeCompare(f2.property);
        }) ?? [];

      this.possibleHeaders =
        capabilities?.headers.sort(function (f1, f2) {
          return f1.text.localeCompare(f2.text);
        }) ?? [];

      this.sortableFields = capabilities?.sortableFields;

      //Aggregate values of component and level property, so that the filter
      //of each can offer a list of possible values.
      const aggregations = ["component", "level"];
      const aggs = {};
      for (let property of aggregations) {
        aggs[property] = {
          terms: {
            field: property + ".keyword",
          },
        };
      }
      const aggregationRes =
        await this.$store.$coreApi.coreLoggingApi.searchLogs(
          this.selectedDomain,
          {
            aggs,
            size: 0, //Set size to 0, so that no documents are returned from the search request
          }
        );

      for (let property of aggregations) {
        //get the filters for each property and add the possible options
        const buckets = aggregationRes.aggregations[property].buckets;
        const options = buckets.map(({ key }) => {
          return {
            value: key,
            text: key,
          };
        });
        const filter = this.possibleFilters.find(
          (filter) => filter.property === property
        );
        this.$set(filter, "options", options);
        this.$set(filter, "type", "list");
      }
    },

    async loadLogs() {
      let aborted = false;
      try {
        if (this.runningAction && this.loadLogsController) {
          this.loadLogsController.abort();
        }
        this.runningAction = true;
        let search = this.searchRequestBody({
          query: this.filterQuery,
          limit: this.limit,
        });

        //add sorting parameters
        const sortBy = this.options?.sortBy;
        const sortDesc = this.options?.sortDesc;
        if (Array.isArray(sortBy)) {
          for (let i = 0; i < sortBy.length; i++) {
            let property = sortBy[i].replace("_source.", "");
            if (!this.sortableFields?.[property]) continue;
            //add .keyword to property key to avoid problems with text fields in elastic search
            if (this.sortableFields?.[property].type === "text")
              property += ".keyword";
            search.sort.push({
              [property]: sortDesc[i] ? "desc" : "asc",
            });
          }
        }

        const controller = new AbortController();
        const signal = controller.signal;
        this.loadLogsController = controller;

        const logs = await this.$store.$coreApi.coreLoggingApi.searchLogs(
          this.selectedDomain,
          search,
          signal
        );

        if (logs.aborted) {
          aborted = true;
          this.loadLogsController = null;
          return;
        }

        this.logs = logs.items;
      } finally {
        if (!aborted) this.runningAction = false;
      }
    },

    renderItemProperty(item, header) {
      const value = this.$getObjectValueByPath(item, header.value);
      const type = header.type;

      if (type === "date") {
        return this.$getLocalizedDate(value, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          fractionalSecondDigits: 3,
          hour12: false,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, //use timezone of user
        });
      }
      if (type === "_source") {
        return JSON.stringify(value);
      }
      return value;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    index() {
      return "log_" + this.selectedDomain;
    },

    tableHeight() {
      return this.getPageHeight() - (this.toolbarExpanded ? 144 : 80) - 12;
    },
  },
};
</script>

<style scoped>
.log-viewer {
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
}

/* 
  Display the columns of the log entries in max. 3 rows,
  if it is longer put an ellipsis at the end of it.
*/
.log-viewer::v-deep .log-value {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.log-viewer
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  white-space: nowrap;
}
</style>